import React, {  useState } from "react";
import { Modal, Button, Dialog, DialogTitle, DialogContent, DialogContentText, Grid,  } from "@material-ui/core";
import "./style.css";
//import {setupSdk} from './../../bundle/sdk'

import {
  Frame,
  GetSDK,
  initComponents,
  SceneComponent,
  ISceneNode,
  ComponentInteractionType,
  orientedBoxType,
  slotType,
  OrientedBox,
  IComponentEventSpy,
  IInteractionEvent,
  //IPlaneRenderer,
  IPlaneInteractionEvent,
  sdkKey,
  IPlaneRenderer,
} from "@mp/common";
import { AppState } from "../AppState";
import { SceneLoader } from "../SceneLoader";
import { ItemList, } from "./ItemList";
import { DrawItemList} from "./DrawItemList";
import { ItemDesc, DrawItemDesc } from "src/types";
import { cameraInputType } from "@mp/common/src/sdk-components/Camera";
import { Vector3, Quaternion, Euler, Matrix4 } from "three";

//import { VideoRenderer } from '@mp/common';

const SelectedColor = 0xffff00;
const SelectedOpacity = 0.1;
const SelectedLineOpacity = 1.0;
const UnselectedColor = 0xffffff;
const UnselectedOpacity = 0.04;
const UnselectedLineOpacity = 0.4;

interface Props {
  appState: AppState;
}

interface State {
  slotNode: SlotNode | null;
  DrawSlotNode : SlotNodeDraw | null;
  payload : IPlaneInteractionEvent | null;
  open: boolean |null;
  isHovered : boolean | null;
}

type SlotNode = {
  node: ISceneNode;
  slotComponent: SceneComponent;
  modelComponent: SceneComponent;
  boxComponent: OrientedBox;
};
type SlotNodeDraw = {
  artist_name: string;
  artist_detail: string;
  piece_name: string;
  piece_detail: string;
  material: string;
  size: string;
  createdDate: string;
  owner: string;
  drawUrl : string;
};


export class Main extends React.Component<Props, State> {
  private sdk: any = null;
  private scene: SceneLoader = null;
  private slots: SlotNode[] = [];
  //private DrawSlots: SlotNodeDraw[] = [];

  private cameraInput: SceneComponent;
  private src: string;
  private applicationKey: string;
  private modelKey: string;
  public isBoolPublic: boolean;

  constructor(props: Props) {
    super(props);

    this.state = {
      slotNode: null,
      DrawSlotNode: null,
      payload: null,
      open: false,
      isHovered : false,
    };

    this.isBoolPublic = false;
    var url;

    // 모바일 디텍션후에  embed 주소로 redirect  기능 차후에 추가,

    //console.log("현재 페이지 주소 " + window.location.href); // 현재 페이지 url 불러오기
    // if (window.location.href.indexOf("localhost")) {
    //   console.log("로컬 실행입니다.");
    //   url = new URL(
    //     "https://premium.360vrmuseum.com/?m=NbA9Vo2qdMV&ss=3&sr=0,3.15&qs=1&play=1&hr=1"
    //   ); // 로컬 테스트용 url 설정
    // }
    // else {
    //   console.log("로컬 실행이 아닙니다.");
    //   const urltmp = window.location.href;
    //   //=> location.href 로 바꿔야할듯

    //   url = new URL(urltmp);
    // }
    // if (
    //   location.hostname === "localhost" ||
    //   location.hostname === "127.0.0.1"
    // ) {
    //   //console.log("로컬이지롱");
    // }
    //console.log("실행확인!");

    // const userAgent = navigator.userAgent.toLowerCase(); // User Agent 문자열을 소문자로 변환하여 저장
    // if (userAgent.indexOf("mobile") !== -1) {
    //   console.log("모바일 디바이스입니다.");
    //   if (
    //     userAgent.indexOf("iphone") !== -1 ||
    //     userAgent.indexOf("ipad") !== -1
    //   ) {
    //     console.log("iOS 디바이스입니다.");
    //     // iOS 디바이스 처리 로직 작성
    //   } else if (userAgent.indexOf("android") !== -1) {
    //     console.log("안드로이드 디바이스입니다.");
    //     // 안드로이드 디바이스 처리 로직 작성
    //   } else {
    //     console.log("모바일 디바이스이지만, iOS나 안드로이드가 아닙니다.");
    //     // 기타 모바일 디바이스 처리 로직 작성
    //   }
    // } else if (userAgent.indexOf("tablet") !== -1) {
    //   console.log("태블릿 디바이스입니다.");
    //   // 태블릿 디바이스 처리 로직 작성
    // } else if (
    //   userAgent.indexOf("macintosh") !== -1 ||
    //   userAgent.indexOf("mac os x") !== -1
    // ) {
    //   console.log("맥북입니다."); // 맥북 디바이스 처리 로직 작성
    // } else {
    //   console.log("데스크톱 디바이스입니다.");
    //   // 데스크톱 디바이스 처리 로직 작성
    // }

    const urltmp = window.location.href;

    url = new URL(urltmp);

    //console.log("urltest : " + url);

    // URLSearchParams 객체
    const urlParams = url.searchParams;

    const params = objectFromQuery(); //

    //params.m = params.m || urlParams.get("m");
    params.m = params.m || urlParams.get("m"); // || "NbA9Vo2qdMV"; // url에서 파라미터 값을 받아와서  모델명을  설정
    //console.log("params.m : " + params.m);
    // params.play = params.play || urlParams.get("play");//다 파라미터 값으로 받아온다라..... 흠
    params.hr = params.hr || urlParams.get("hr"); // 왠지 모르겠지만 하이라이트릴 설정이 안됨.
    params.play = params.play || urlParams.get("play") || "0";
    
    params.qs = "1" || params.qs || urlParams.get("qs");
    params.sr = params.sr || urlParams.get("sr");
    params.ss = params.ss || urlParams.get("ss");

    params.exhibitioncode =
      params.exhibitioncode || urlParams.get("exhibitioncode");

    // ensure applicationKey is inserted into the bundle query string
    params.applicationKey = params.applicationKey || sdkKey;
    this.applicationKey = params.applicationKey;

    

    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");
    //this.src = `./bundle/showcase.html?${queryString}`; // 얘가 공유 주소값 컨트롤 인듯?... 나중에 테스트 요망
    this.src = `./bundle/showcase.html?${queryString}`;
    //this.src = `../../../../bundle/showcase.html?${queryString}`; // 얘가 공유 주소값 컨트롤 인듯?... 나중에 테스트 요망  // 얘 위치를  밖으로 옮겨야 할듯?
    // console.log("*************src : "+this.src);
    this.handleListSelection = this.handleListSelection.bind(this);
    this.drawList = this.drawList.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.modelKey = params.m;

    // save load log
    this.loadModel(this.modelKey);
    // var BGMImage = document.getElementById("audio - player");
    // BGMImage.addEventListener('click',function(){
    //   console.log("이미지 클릭");
    // })
  }

  async componentDidMount() {
    // ??
    this.sdk = await GetSDK("sdk-iframe", this.applicationKey);
    await this.spotClickEvent(this.modelKey);
    await this.tagClickEvent(this.modelKey);
    await this.playClickEvent(this.modelKey);
    await initComponents(this.sdk);
    await this.createCameraControl(this.sdk);
    await this.sdk.Scene.configure((renderer: any, three: any) => {
      renderer.physicallyCorrectLights = true;
      renderer.outputEncoding = three.sRGBEncoding;
      renderer.shadowMap.enabled = true;
      renderer.shadowMap.bias = 1;
      renderer.shadowMap.type = three.PCFSoftShadowMap;
    });

    this.scene = new SceneLoader(this.sdk);

    const slots: SlotNode[] = [];

    
    

    //const DrawSlots: SlotNodeDraw[] = [];
    


    class ClickSpy implements IComponentEventSpy<IInteractionEvent> {
      public eventType = ComponentInteractionType.CLICK;
      constructor(private mainComponent: Main) {}
      onEvent(payload: IInteractionEvent) {
        console.log("clickspycheck");
        this.mainComponent.handleOrientedBoxInteraction(
          payload.node,
          payload.component,
          payload.type
        );
      }
    }

    class ClickSpyTest implements IComponentEventSpy<IPlaneInteractionEvent> {
      public eventType = ComponentInteractionType.CLICK;
      constructor(private mainComponent: Main) {}
      onEvent(payload: IPlaneInteractionEvent) {
        this.mainComponent.drawInteraction(payload, this.eventType);
        // console.log(
        //   "this.mainComponent.drawInteraction(payload, this.eventType) : " +
        //     this.mainComponent.drawInteraction(payload, this.eventType)
        // );
        // this.mainComponent.setPayload(payload);

        this.mainComponent.handleOpen();
      }
    }

    class HoverSpy implements IComponentEventSpy {
      public eventType = ComponentInteractionType.HOVER;
      constructor(private mainComponent: Main) {}
      onEvent(payload: { hover: boolean }) {
        this.mainComponent.cameraInput.inputs.suppressClick = !payload.hover;
      }
    }

    const clickSpy = new ClickSpy(this);
    const clickSpyTest = new ClickSpyTest(this);

    const hoverSpy = new HoverSpy(this);
    const findSlots = (node: ISceneNode) => {
      let slot: SceneComponent = null;
      let model: SceneComponent = null;
      let box: OrientedBox = null;
      let plane: IPlaneRenderer = null;
      const componentIterator: IterableIterator<SceneComponent> =
        node.componentIterator();
      for (const component of componentIterator) {
        if (component.componentType === slotType) {
          slot = component;
        } else if (component.componentType === "mp.gltfLoader") {
          //console.log("mp.gltfcheck");
          model = component;
          //console.log("model : " );

          //console.log(model.context);
          //console.log(model);
        } else if (component.componentType == orientedBoxType) {
          box = component as OrientedBox;
          box.spyOnEvent(clickSpy);
          box.spyOnEvent(hoverSpy);
          box.inputs.color = UnselectedColor;
          box.inputs.opacity = UnselectedOpacity;
        } else if (component.componentType == "mp.planeRenderer") {
          // console.log(plane + "" + clickSpyTest);
          plane = component as IPlaneRenderer;
          plane.spyOnEvent(clickSpyTest);
          // console.log("testtesttesttest");
          //this.setState(null);
        }
      }

      if (slot && model) {
        slots.push({
          node: node,
          slotComponent: slot,
          modelComponent: model,
          boxComponent: box,
        });
      }
      // DrawSlots.push({

      // })
      // if (plane) {
      //   DrawSlots.push({plane},
      //   );
      // }
    };

    this.slots = slots;
    //this.DrawSlots = DrawSlots;
    await this.scene.load("AAWs9eZ9ip6", findSlots);

    //console.log(this.scene);
    //console.log(this.sdk);
  }

  private handleListSelection(item: ItemDesc) {
    const slotNode = this.state.slotNode;
    if (!slotNode) {
      return;
    }
    slotNode.slotComponent.inputs.model = item.url;
    slotNode.modelComponent.inputs.Position.x = item.position.x;
    slotNode.modelComponent.inputs.Position.y = item.position.y;
    slotNode.modelComponent.inputs.Position.z = item.position.z;
    slotNode.modelComponent.inputs.localRotation.x = item.rotation.x;
    slotNode.modelComponent.inputs.localRotation.y = item.rotation.y;
    slotNode.modelComponent.inputs.localRotation.z = item.rotation.z;
    slotNode.modelComponent.inputs.localScale.x = item.scale.x;
    slotNode.modelComponent.inputs.localScale.y = item.scale.y;
    slotNode.modelComponent.inputs.localScale.z = item.scale.z;
  }
  private drawList(drawItem: DrawItemDesc) {
    const drawSlotNode = this.state.payload;

    if (!drawSlotNode) {
      return;
    }
    drawSlotNode.artist_name = drawItem.artist_name;
    //console.log("drawSlotNode.artist_name : " + drawSlotNode.artist_name);
    //console.log("drawItem.artist_name : " + drawItem.artist_name);
    drawSlotNode.artist_detail = drawItem.artist_detail;
    drawSlotNode.piece_name = drawItem.piece_name;
    drawSlotNode.piece_detail = drawItem.piece_detail;
    drawSlotNode.material = drawItem.material;
    drawSlotNode.size = drawItem.size;
    drawSlotNode.createdDate = drawItem.createdDate;
    drawSlotNode.owner = drawItem.owner;
    drawSlotNode.drawUrl = drawItem.drawUrl;
  }

  // 얘를 한개 더 만들면 될듯?
  private handleOrientedBoxInteraction(
    node: ISceneNode,
    component: SceneComponent,
    interactionType: ComponentInteractionType
  ) {
    if (interactionType === ComponentInteractionType.CLICK) {
      // select this node
      for (const slot of this.slots) {
        if (slot.node === node) {
          for (const componentSearch of node.componentIterator()) {
            if (componentSearch === component) {
              const lastSlotNode = this.state.slotNode;
              if (lastSlotNode) {
                lastSlotNode.boxComponent.inputs.color = UnselectedColor;
                lastSlotNode.boxComponent.inputs.opacity = UnselectedOpacity;
                lastSlotNode.boxComponent.inputs.lineOpacity =
                  UnselectedLineOpacity;
              }

              if (lastSlotNode === slot) {
                this.cameraInput.inputs.focus = null;

                this.setState({
                  slotNode: null,
                });
              } else {
                this.setState({
                  slotNode: slot,
                });

                slot.boxComponent.inputs.color = SelectedColor;
                slot.boxComponent.inputs.opacity = SelectedOpacity;
                slot.boxComponent.inputs.lineOpacity = SelectedLineOpacity;
                this.cameraInput.inputs.focus = node.position;
              }
            }
          }
        }
      }
    }
  }

  private drawInteraction(
    payloadInputs: IPlaneInteractionEvent,
    interactionType: ComponentInteractionType
  ) {
    if (interactionType === ComponentInteractionType.CLICK) {
      // select this node
      // for (const componentSearch of payloadInputs.com)
      this.setState({ payload: payloadInputs });
      // for (const slot of this.DrawSlots){
      //   this.setState({ payload: slot });

      // }
    }
  }

  myModal() {
    const [open, setOpen] = useState(false);

    const handleClose = () => {
      setOpen(false);
    };
    return (
      <div>
        <Button onClick={() => setOpen(true)}>Open Modal</Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div>Modal Content</div>
        </Modal>
      </div>
    );
  }

  // setPayload(payload: IPlaneInteractionEvent) {
  //   this.setState({ payload });
  // }
  handleClose() {
    this.setState({ open: false });
  }

  handleOpen() {
    this.setState({ open: true });
  }

  ImageBigger() {
    this.setState({ isHovered: false });
  }
  ImageSmall() {
    this.setState({ isHovered: false });
  }

  render() {
    let filteredItems: ItemDesc[] = [];
    let DrawfilteredItems: DrawItemDesc;
    const { slotNode, DrawSlotNode } = this.state;
    //const [isHovered, setIsHovered] = useState(false); // hover 여부 상태 저장

    //console.log("payload artist_name : " + payload.artist_name);

    if (DrawSlotNode) {
      const { drawInformation } = this.props.appState;
      //drawInformation.get(DrawSlotNode.artist_name);
      DrawfilteredItems = drawInformation; //.get(DrawSlotNode.artist_name); // drawInformation.get(payload.artist_name);
      console.log("drawInformation payload");
    }
    //payload.artist_name = this.props.appState.drawInformation
    if (slotNode) {
      const { items, slots } = this.props.appState;
      const category = slots.get(slotNode.node.name);

      if (category) {
        filteredItems = items.get(category);
      }
    }

    var titleTextSize = "1.5rem";
    var descriptionTextSize = "0.8rem";

    // const st = () => {
    //   this.setState({ open: false });
    // };
    //console.log(this.state.payload?.drawUrl)

    return (
      <div className="main">
        <ItemList
          items={filteredItems}
          onSelected={this.handleListSelection}
        ></ItemList>
        <DrawItemList
          DrawItems={DrawfilteredItems}
          onSelected={this.drawList}
        ></DrawItemList>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="simple-dialog-title"
          aria-describedby="simple-dialog-description"
          fullWidth
          PaperProps={{
            style: {
              width: "auto", // Dialog의 너비를 75vw로 설정
              minWidth: "60vw", // Dialog의 최대 너비를 제한하지 않음
              maxWidth: "auto",
              maxHeight: "auto",

              /* 스크롤바 디자인 추가 */
              overflowY: "auto", // Y축 스크롤바를 자동으로 표시
              WebkitOverflowScrolling: "touch", // 모바일에서 스크롤이 부드럽게 작동하도록 설정
              scrollbarWidth: "thin", // 스크롤바의 너비를 얇게 설정
              scrollbarColor: "#9e9e9e #f5f5f5", // 스크롤바의 색상 지정

              padding: "0px",
              margin: "0px",
            },
          }}
        >
          <Grid
            container
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              background: "linear-gradient(to right, white 50%, white 50%)",
              padding: "0px",
              margin: "0px",
            }}
          >
            {/* 이미지 영역 */}
            <Grid
              item
              xs={6}
              sm={6}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0px",
                margin: "0px",
              }}
            >
              <img
                src={this.state.payload?.drawUrl}
                alt="image load failed1"
                width="auto"
                height="auto"
                style={{
                  display: "flex",
                  minWidth: "90%",
                  maxHeight: "auto",
                  maxWidth: "auto",
                  paddingLeft: "45px",
                  paddingTop: "45px",
                  paddingBottom: "45px",
                  paddingRight: "20px",
                  margin: "0px",
                  transition: "transform 0.2s ease-in-out", // 애니메이션 효과 추가
                  transform: this.state.isHovered ? "scale(1)" : "scale(1)", // hover 시 확대되는 효과
                }}
                onMouseEnter={() => this.ImageBigger()} // 마우스가 요소 위에 올라갔을 때 처리
                onMouseLeave={() => this.ImageSmall()} // 마우스가 요소에서 벗어났을 때 처리
                onClick={() =>
                  window.open(this.state.payload?.drawUrl, "_blank")
                } // 이미지 클릭 시 새 창으로 이미지 열기
              />
            </Grid>

            {/* 텍스트 영역 */}

            <Grid
              item
              xs={6}
              sm={6}
              style={{
                width: "auto",
                display: "flex",
                flexDirection: "column",
                fontSize: "10px",
                justifyContent: "center",
                padding: "0px",
                paddingRight: "10px",
                margin: "0px",
              }}
            >
              <div
                onClick={this.handleClose}
                style={{
                  textAlign: "right",
                  fontSize: "20px",
                  position: "absolute",
                  top: "20px",
                  right: "20px",

                  cursor: "pointer",
                  transform: "scaleX(1.35)",
                }}
              >
                X
              </div>

              <DialogTitle
                id="simple-dialog-title"
                style={{ whiteSpace: "pre-line" }}
              >
                <div style={{ fontSize: titleTextSize, fontWeight: "bold" }}>
                  {this.state.payload?.piece_name}
                </div>
              </DialogTitle>
              <DialogContent style={{ whiteSpace: "pre-line" }}>
                <DialogContentText
                  id="simple-dialog-description"
                  style={{ fontSize: descriptionTextSize }}
                >
                  작가명 : {this.state.payload?.artist_name}
                </DialogContentText>
                <DialogContentText
                  id="simple-dialog-description"
                  style={{ fontSize: descriptionTextSize }}
                >
                  작가설명 : {this.state.payload?.artist_detail}
                </DialogContentText>
                <DialogContentText
                  id="simple-dialog-description"
                  style={{ fontSize: descriptionTextSize }}
                >
                  재료 : {this.state.payload?.material}
                </DialogContentText>
                <DialogContentText
                  id="simple-dialog-description"
                  style={{ fontSize: descriptionTextSize }}
                >
                  크기 : {this.state.payload?.size}
                </DialogContentText>
                <DialogContentText
                  id="simple-dialog-description"
                  style={{ fontSize: descriptionTextSize }}
                >
                  년도 :{" "}
                  {this.state.payload?.createdDate ? this.state.payload?.createdDate.split("T")[0].split("-")[0] : '-'}
                  년
                </DialogContentText>
                <DialogContentText
                  id="simple-dialog-description"
                  style={{ fontSize: descriptionTextSize }}
                >
                  작품설명 : {this.state.payload?.piece_detail}
                </DialogContentText>
                <DialogContentText
                  id="simple-dialog-description"
                  style={{ fontSize: descriptionTextSize }}
                >
                  소장처 : {this.state.payload?.owner}
                </DialogContentText>
              </DialogContent>
            </Grid>
          </Grid>
        </Dialog>

        <Frame src={this.src}></Frame>
      </div>
    );
  }

  async createCameraControl(theSdk: any) {
    const cameraNode = await theSdk.Scene.createNode();
    const cameraPose = await theSdk.Camera.getPose();
    this.cameraInput = cameraNode.addComponent(cameraInputType);
    // convert sdk pose to THREE.js objects
    this.cameraInput.inputs.startPose = {
      position: new Vector3(
        cameraPose.position.x,
        cameraPose.position.y,
        cameraPose.position.z
      ),
      quaternion: new Quaternion().setFromEuler(
        new Euler(
          (cameraPose.rotation.x * Math.PI) / 180,
          (cameraPose.rotation.y * Math.PI) / 180,
          ((cameraPose.rotation.z || 0) * Math.PI) / 180,
          "YXZ"
        )
      ),
      projection: new Matrix4().fromArray(cameraPose.projection).transpose(),
    };
    const cameraControl = cameraNode.addComponent("mp.camera");
    cameraControl.bind("camera", this.cameraInput, "camera");

    cameraNode.start();
  }

  async spotClickEvent(modelKey: string) {
    this.sdk.on(
      this.sdk.Sweep.Event.ENTER,
      function (oldSweep: string, newSweep: string) {
        //console.log(oldSweep, newSweep);
        if (oldSweep && oldSweep !== newSweep) {
          const url: string = `https://embed.360vrmuseum.com/api/spot/${modelKey}/${newSweep}`;

          var xhr = new XMLHttpRequest();
          xhr.open("POST", url, true);

          xhr.onload = function (e) {
            console.log("spot success");
          };

          xhr.onerror = function (e) {
            console.error(xhr.statusText);
          };

          xhr.send("");
        }
      }
    );
  }

  async tagClickEvent(modelKey: string) {
    this.sdk.on(this.sdk.Mattertag.Event.CLICK, function (sid: string) {
      const url: string = `https://embed.360vrmuseum.com/api/tag/${modelKey}/${sid}`;

      var xhr = new XMLHttpRequest();
      xhr.open("POST", url, true);

      xhr.onload = function (e) {
        console.log("tag success");
      };

      xhr.onerror = function (e) {
        console.error(xhr.statusText);
      };

      xhr.send("");
    });
  }

  async playClickEvent(modelKey: string) {
    this.sdk.on(this.sdk.App.Event.PHASE_CHANGE, function (app: string) {
      if (app == "appphase.playing") {
        const url: string = `https://embed.360vrmuseum.com/api/play/showcase/${modelKey}`;

        var xhr = new XMLHttpRequest();
        xhr.open("POST", url, true);

        xhr.onload = function (e) {
          console.log("play success");
        };

        xhr.onerror = function (e) {
          console.error(xhr.statusText);
        };

        xhr.send("");
      }
    });
  }

  private async loadModel(modelKey: string) {
    const url: string = `https://embed.360vrmuseum.com/api/load/showcase/${modelKey}`;
    console.log("===================");
    console.log(modelKey);
    var xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);

    xhr.onload = function (e) {
      console.log("load success");
    };

    xhr.onerror = function (e) {
      console.error(xhr.statusText);
    };

    xhr.send("");
  }
}



// from cwf/modules/browser.ts
export const objectFromQuery = (url?: string): { [key: string]: string } => {
  const regex = /[#&?]([^=]+)=([^#&?]+)/g;
  url = url || window.location.href;
  const object: { [param: string]: string } = {};
  let matches;
  // regex.exec returns new matches on each
  // call when we use /g like above
  while ((matches = regex.exec(url)) !== null) {
     [matches[1]] = decodeURIComponent(matches[2]);
  }
  return object;
};


