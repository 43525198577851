import { Events } from 'hls.js';
// import { ImgHTMLAttributes } from 'react';
import { SceneComponent, ComponentOutput } from '../SceneComponent';
// import { Button, Modal } from '@material-ui/core';
// import { useState } from 'react';
enum Event {
  Toggle = "toggle",
  Unmute ="unmute"
}

type Inputs = {
  initialState: boolean;
};

type Outputs = {
  state: boolean;
  negated: boolean;
} & ComponentOutput;

type Events = {
  [Event.Toggle]: boolean;
};


class ToggleState extends SceneComponent {
  inputs: Inputs = {
    initialState: true,
  }
  outputs = {
    state: true,
    negated: false,
  } as Outputs;

  events = {
    toggle: true,
  } as Events;

  onInit() {
    this.outputs.state = this.inputs.initialState;
    this.outputs.negated = !this.outputs.state;
    
     
  }
  

  onEvent(eventType: string, eventData: unknown) {
    //console.log("inputs.initialState"+ this.inputs.initialState);
    // const BGM = document.getElementsByClassName(".unMuteBGM");
    // const UnmuteBGM = document.querySelector(".unMuteBGM");

    
    // console.log("UnmuteBGM : " + UnmuteBGM.className);
    // BGM.addEventListener("click", function (event) {
    //   console.log("이미지 클릭  event :" + event);
    // });
    if(eventType === Event.Toggle) 
    {
      //console.log("클릭클릭  Event.Toggle : "+  Event.Toggle +", eventType : "+ typeof eventType);
     // console.log("Event.Toggle");
      this.outputs.state = !this.outputs.state;
      this.outputs.negated = this.outputs.state;

      
    }
    
  }

  

}

// function MyModal() {
//   const [open, setOpen] = useState(false);

//   const handleClose = () => {
//     setOpen(false);
//   };

//   return (
//     <div>
//       <Button onClick={() => setOpen(true)}>Open Modal</Button>
//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="simple-modal-title"
//         aria-describedby="simple-modal-description"
//       >
//         <div>Modal Content</div>
//       </Modal>
//     </div>
//   );
// }

export const toggleStateType = 'mp.toggleState';
export function makeToggleState() {
  return new ToggleState();
}


