import { SceneComponent, ComponentInteractionType } from "../SceneComponent";
import { Texture, Mesh, MeshBasicMaterial, Object3D } from "three";
//import React, { Component } from "react";
//import {  Modal } from "@material-ui/core";

//import { NURBSCurve } from "./../../../../node_modules/@types/three/examples/jsm/curves/NURBSCurve";
//import { NURBSSurface } from "./NURBScurveScript/curves/NURBSSurface";
//import { NURBSSurface } from "./../../../../node_modules/@types/three/examples/jsm/curves/NURBSSurface";

//import { ParametricBufferGeometry } from "./../../../../node_modules/@types/three/src/geometries/ParametricGeometry";
//import { NURBSSurface } from "./NURBScurveScript/curves/NURBSSurface";

export type Size = { w: number; h: number };
export interface IPlaneInteractionEvent {
  artist_name: string;
  artist_detail: string;
  piece_name: string;
  piece_detail: string;
  material: string;
  size: string;
  createdDate: string;
  owner: string;
  drawUrl : string;
}
interface screenModeUnionType {
  Plane: number;
  Curved: number;
  Cascading: number;
  FrameRound: number;
  ThreePlane: number;
}
type Inputs = {
  texture: Texture | null;
  aspect: number;
  transparent: boolean;
  visible: boolean;
  opacity: number;
  polygonOffset: boolean;
  polygonOffsetFactor: number;
  polygonOffsetUnits: number;
  localScale: { x: number; y: number; z: number };
  localPosition: { x: number; y: number; z: number };
  localRotation: { x: number; y: number; z: number };
  localCurveScale: { x: number; y: number; z: number };
  widthSegments: number;
  heightSegments: number;
  bandDepth: number;
  isCollider: boolean;
  isDoubleSide: boolean;
  isBorderRound: boolean;
  radiusNumber: number;
  isCurvedScreen: boolean;
  isLocalRotation: boolean;
  isCascadingScreen: boolean;

  screenModeNumber: number;
  PlaneSegments: number;
  defaultRoundNumber: number;
  ThreePlanePositionNumber: {
    plane1: { x: number; y: number; z: number };
    plane2: { x: number; y: number; z: number };
    plane3: { x: number; y: number; z: number };
  };

  planeColorString: number;
  informationsString: {
    artist_name: string;
    artist_detail: string;
    piece_name: string;
    piece_detail: string;
    material: string;
    size: string;
    createdDate: string;
    owner: string;
  };
  DrawUrlInformationsString: {
    drawUrl: string;
  };

  isIntroTextPosition: boolean;
  IntroTextPostion: { x: number; y: number; z: number };
};

export class PlaneRenderer extends SceneComponent implements IPlaneRenderer {
  private mesh: Mesh;
  private pivotNode: Object3D;

  inputs: Inputs = {
    texture: null,
    aspect: 1,
    transparent: true,
    visible: true,
    opacity: 1,
    polygonOffset: true,
    polygonOffsetFactor: 0,
    polygonOffsetUnits: 0,
    localScale: { x: 1, y: 1, z: 1 },
    localPosition: { x: 0, y: 0, z: 0 },
    localRotation: { x: 0, y: 0, z: 0 },
    localCurveScale: { x: 1, y: 1, z: 1 },
    widthSegments: 0,
    heightSegments: 0,
    bandDepth: 0,
    isCollider: true,
    isDoubleSide: false,
    isBorderRound: false,
    radiusNumber: 0,
    isCurvedScreen: false,
    isLocalRotation: false,
    isCascadingScreen: false,
    screenModeNumber: 0,
    PlaneSegments: 20,
    defaultRoundNumber: 1,
    ThreePlanePositionNumber: {
      plane1: { x: 0, y: 0, z: 0 },
      plane2: { x: 0, y: 0, z: 0 },
      plane3: { x: 0, y: 0, z: 0 },
    },
    planeColorString: 0xb7b7b7,
    informationsString: {
      artist_name: null,
      artist_detail: null,
      piece_name: null,
      piece_detail: null,
      material: null,
      size: null,
      createdDate: null,
      owner: null,
    },
    DrawUrlInformationsString: {
      drawUrl: null,
    },

    isIntroTextPosition: false,
    IntroTextPostion: { x: 0, y: 0, z: 0 },
  };

  events = {
    [ComponentInteractionType.CLICK]: true,
    [ComponentInteractionType.HOVER]: true,
  };
  screenMode: screenModeUnionType = {
    Plane: 0,
    Curved: 1,
    Cascading: 2,
    FrameRound: 3,
    ThreePlane: 4,
  };
  onInit() {
    let tmpIsCollider;
    tmpIsCollider = this.inputs.isCollider;
    const THREE = this.context.three;

    let tmpPlaneColorStringtoNumber;
    //this.inputs.planeColorString= 0xb0b0b0,// plane 밝기 컨트롤러
    tmpPlaneColorStringtoNumber = Number(this.inputs.planeColorString);

    this.pivotNode = new THREE.Group();
    var DoubleSide = THREE.FrontSide;
    if (this.inputs.isDoubleSide) {
      DoubleSide = THREE.DoubleSide;
    }

    this.mesh = new THREE.Mesh(
      new THREE.PlaneBufferGeometry(1.0, 1.0), //BufferGeometryVariable,//
      new THREE.MeshBasicMaterial({
        color: new THREE.Color(tmpPlaneColorStringtoNumber),

        transparent: this.inputs.transparent,
        map: this.inputs.texture,
        opacity: this.inputs.opacity,
        polygonOffset: this.inputs.polygonOffset,
        polygonOffsetFactor: this.inputs.polygonOffsetFactor,
        polygonOffsetUnits: this.inputs.polygonOffsetUnits,
        side: DoubleSide,
      })
    );

    this.mesh.scale.set(
      this.inputs.localScale.x,
      this.inputs.localScale.y / this.inputs.aspect,
      this.inputs.localScale.z
    );
    
    this.mesh.position.set(
      this.inputs.localPosition.x,
      this.inputs.localPosition.y,
      this.inputs.localPosition.z
    );
    this.mesh.updateMatrixWorld();

    this.pivotNode.add(this.mesh);

    this.outputs.objectRoot = this.pivotNode;
    //this.outputs.collider = this.pivotNode;

    if (tmpIsCollider) {
      this.outputs.collider = this.pivotNode;
    }

    this.mesh.visible = this.inputs.visible;
  }

  onEvent(eventType: string, eventData: unknown) {
    // console.log("클릭클릭pn");

    if (eventType === ComponentInteractionType.CLICK) {
      //console.log(JSON.stringify( this.inputs.informationsString));
      this.notify(eventType, {
        artist_name: this.inputs.informationsString.artist_name,
        artist_detail: this.inputs.informationsString.artist_detail,
        piece_name: this.inputs.informationsString.piece_name,
        piece_detail: this.inputs.informationsString.piece_detail,
        material: this.inputs.informationsString.material,
        size: this.inputs.informationsString.size,
        createdDate: this.inputs.informationsString.createdDate,
        owner: this.inputs.informationsString.owner,
        drawUrl: this.inputs.DrawUrlInformationsString.drawUrl,
      }); // 업데이트
    }

    if (eventType === ComponentInteractionType.HOVER) {
      this.notify(ComponentInteractionType.HOVER, {
        hover: (<{ hover: boolean }>eventData).hover,
      });
    }
  }

  onInputsUpdated(oldInputs: Inputs) {
    if (oldInputs.transparent !== this.inputs.transparent) {
      (this.mesh.material as MeshBasicMaterial).transparent =
        this.inputs.transparent;
    }

    if (oldInputs.texture !== this.inputs.texture) {
      const material = this.mesh.material as MeshBasicMaterial;
      //this.inputs.texture.format = this.context.three.RGBAFormat;
      material.map = this.inputs.texture; //this.texture.format = THREE.RGBFormat;
      material.needsUpdate = true;

      //console.log("마테리얼 업데이트 ");
    }
    //console.log("계속 도나?");
    if (oldInputs.visible !== this.inputs.visible) {
      console.log("계속 도나?", this.mesh.visible, this.inputs.visible);
      this.mesh.visible = this.inputs.visible;

      //console.log("??????????????????????????????????????????????????????????");
    }

    if (oldInputs.polygonOffset !== this.inputs.polygonOffset) {
      const material = this.mesh.material as MeshBasicMaterial;
      material.polygonOffset = this.inputs.polygonOffset;
      material.polygonOffsetFactor = this.inputs.polygonOffsetFactor;
      material.polygonOffsetUnits = this.inputs.polygonOffsetUnits;
      //material.color = "0x000000";

      //material.side = THREE.DoubleSide;
    }
    //this.plane.material.
    //this.mesh.material  = new THREE.MeshBasicMaterial(map:Texture, side: THREE.DoubleSide)

    // 모서리 라운드 처리 함수
    function FrameRoundFunction(inputsRadiusNumber: number, inputsThree: any) {
      const THREE = inputsThree;
      //const videoGeometry = new THREE.CircleGeometry(0.7, 200);

      var radiusNumber = inputsRadiusNumber;
      var XTotalNumber = 1;
      var YTotalNumber = 1;
      const x = 0, //this.inputs.localPosition.x,
        y = 0; //this.inputs.localPosition.y;
      const roundShape = new THREE.Shape();

      roundShape.moveTo(x, y + radiusNumber);
      roundShape.quadraticCurveTo(x, y, x + radiusNumber, y);
      roundShape.quadraticCurveTo(
        x + (XTotalNumber - radiusNumber),
        y + 0,
        x + (XTotalNumber - radiusNumber),
        y + 0 //
      );
      roundShape.quadraticCurveTo(
        x + XTotalNumber,
        y + 0,
        x + XTotalNumber,
        y + radiusNumber
      );
      roundShape.quadraticCurveTo(
        x + XTotalNumber,
        y + (YTotalNumber - radiusNumber),
        x + XTotalNumber,
        y + (YTotalNumber - radiusNumber)
      );
      roundShape.quadraticCurveTo(
        x + XTotalNumber,
        y + YTotalNumber,
        x + XTotalNumber - radiusNumber,
        y + YTotalNumber
      );
      roundShape.quadraticCurveTo(
        x,
        y + YTotalNumber,
        x + radiusNumber,
        y + YTotalNumber
      );
      roundShape.quadraticCurveTo(
        x,
        y + YTotalNumber,
        x,
        y + YTotalNumber - radiusNumber
      );

      const videoGeometry = new THREE.ShapeGeometry(roundShape);
      return videoGeometry;
    }

    // 커브드스크린 함수
    function CurvePlane(
      g: any,
      z: any,
      inputsThree: any,
      defaultRoundNumber: any
    ) {
      const THREE = inputsThree;
      let p = g.parameters;

      let hw = p.width * 0.5; // 너비를 2등분 함

      let a = new THREE.Vector2(-hw, 0); // 시작지점
      let b = new THREE.Vector2(0, z); // 굴곡 정도
      let c = new THREE.Vector2(hw, 0); // 끝 지점

      let ab = new THREE.Vector2().subVectors(a, b); //   a b 사이의 거리
      let bc = new THREE.Vector2().subVectors(b, c); //   b c 사이의 거리
      let ac = new THREE.Vector2().subVectors(a, c); //   a c 사이의 거리

      let r =
        (ab.length() * bc.length() * ac.length()) /
        (2 * Math.abs(ab.cross(ac)));

      let center = new THREE.Vector2(0, z - r);
      let baseV = new THREE.Vector2().subVectors(a, center);
      let baseAngle = baseV.angle() - Math.PI * 0.5;
      let arc = baseAngle * 2;

      let uv = g.attributes.uv;
      let pos = g.attributes.position;
      let mainV = new THREE.Vector2();

      for (let i = 0; i < uv.count; i++) {
        let uvRatio = 1 - uv.getX(i);
        let y = pos.getY(i);
        mainV.copy(c).rotateAround(center, arc * uvRatio);
        pos.setXYZ(i, mainV.x, y, -mainV.y * defaultRoundNumber);
      }

      //pos.needsUpdate = true;
      return g;
    }

    // 계단식 스크린 함수
    function CascadedPlane(
      g: any,
      z: any,
      inputsThree: any,
      localCurveScale: any
    ) {
      const THREE = inputsThree;
      //let p = g.parameters;

      let uv = g.attributes.uv;
      let pos = g.attributes.position;
      let mainV = new THREE.Vector2();
      let planeSize = localCurveScale.y / Math.sqrt(uv.count);

      let uvCount = 0;
      let y = 0;
      for (let i = 0; i < uv.count; i++) {
        mainV.x = pos.getX(i);
        mainV.y = pos.getZ(i);
        y = pos.getY(i);
        if (i % (Math.sqrt(uv.count) * 2) == 0) {
          uvCount++;
        }
        pos.setXYZ(
          i,
          mainV.x,
          y + planeSize * uvCount,
          mainV.y + planeSize * uvCount
        );
      }

      //pos.needsUpdate = true;
      return g;
    }

    function ThreePlaneScreen(
      g: any,
      z: any,
      inputsThree: any,
      localCurveScale: any
    ) {
      const THREE = inputsThree;
      //let p = g.parameters;

      let uv = g.attributes.uv;
      let pos = g.attributes.position;
      let mainV = new THREE.Vector2();
      let planeSize = localCurveScale.y / Math.sqrt(uv.count);

      //let uvCount = 0;
      //let y = 0;
      let Plane1VertexNumber = 4;
      //let Plane2VertexNumber = 3;
      //let Plane3VertexNumber = 4;

      // let stepNubmer = 1;
      let stepCountNubmer = 0;

      for (let i = 0; i < uv.count; i++) {
        mainV.x = pos.getX(i);
        mainV.y = pos.getZ(i);
        // if (i % (Math.sqrt(uv.count) * 2) == 0) {
        //   uvCount++;
        // }

        // console.log(
        //   "i % (Math.sqrt(uv.count) * 2)  : " + (i % (Math.sqrt(uv.count) * 2))
        // );
        stepCountNubmer++;
        if (i % (Math.sqrt(uv.count) * 2) == 0) {
          // 한줄 끝나면 초기화
          stepCountNubmer = 0;
        }

        if (stepCountNubmer >= 0 && stepCountNubmer <= 4) {
          console.log("confirm1 : " + i);
          pos.setXYZ(
            i,
            mainV.x - Plane1VertexNumber * planeSize,
            pos.getY(i),
            mainV.y + Plane1VertexNumber * planeSize
          );
        } else {
          console.log("confirm2 : " + i);
          pos.setXYZ(i, mainV.x, pos.getY(i), mainV.y);
        }
      }
      //pos.needsUpdate = true;
      return g;
    }

    const THREE = this.context.three;

    let geom = new THREE.PlaneGeometry(
      this.inputs.localCurveScale.x,
      this.inputs.localCurveScale.y,
      this.inputs.PlaneSegments, //this.inputs.widthSegments,
      this.inputs.PlaneSegments //this.inputs.heightSegments
    );

    switch (this.inputs.screenModeNumber) {
      case this.screenMode.Plane:
        // console.log("평면");

        break;
      case this.screenMode.Curved:
        this.mesh.geometry = CurvePlane(
          geom,
          this.inputs.bandDepth,
          this.context.three,
          this.inputs.defaultRoundNumber
        );
        break;
      case this.screenMode.Cascading:
        this.mesh.geometry = CascadedPlane(
          geom,
          this.inputs.bandDepth,
          this.context.three,
          this.inputs.localCurveScale
        );
        break;
      case this.screenMode.FrameRound:
        this.mesh.geometry = FrameRoundFunction(
          this.inputs.radiusNumber,
          this.context.three
        );
        break;
      case this.screenMode.ThreePlane:
        this.mesh.geometry = ThreePlaneScreen(
          geom,
          this.inputs.bandDepth,
          this.context.three,
          this.inputs.localCurveScale
        );
        break;

      default:
        console.log("ScreenModeError");
        break;
    }

    if (this.inputs.isLocalRotation) {
      // 자체 회전값을 가질때
      this.mesh.rotation.set(
        this.inputs.localRotation.x,
        this.inputs.localRotation.y,
        this.inputs.localRotation.z
      );
    }

    this.mesh.position.set(
      this.inputs.localPosition.x + this.inputs.IntroTextPostion.x,
      this.inputs.localPosition.y + this.inputs.IntroTextPostion.y,
      this.inputs.localPosition.z + this.inputs.IntroTextPostion.z
    );
  }

  onDestroy() {
    this.outputs.collider = null;
    this.outputs.objectRoot = null;

    (this.mesh.material as MeshBasicMaterial).dispose();
    this.mesh.geometry.dispose();
  }
}

export interface IPlaneRenderer extends SceneComponent {
  inputs: Inputs;
}

export const planeRendererType = "mp.planeRenderer";
export function makePlaneRenderer() {
  return new PlaneRenderer();
}
