import { SceneComponent, IPainter2d, Size,test1, ComponentOutput } from "@mp/common";

type Inputs = {
  src: string | null;
  src2: string | null;
  srcPosition: { x: number; y: number };
  srcSize: Size;
  destPosition: { x: number; y: number };
  destSize: Size;

  isGalleryMode: boolean;
  isWallBool: boolean;
  isShadowMode: boolean;
  srcImageSize: string;

  SrcImageSize: { w: number; h: number };
  
};

type Outputs = {
  painter: IPainter2d | null;

  outputSrcImageSize: { w: number; h: number } | null;
  outputSize: null;
  callbackImageSize: Size;
} & ComponentOutput;

class CanvasImage extends SceneComponent implements IPainter2d, test1 {
  private image: HTMLImageElement | null = null;
  private imageSize: HTMLImageElement | null = null;

  inputs: Inputs = {
    src: null,
    src2: null,
    srcPosition: { x: 0, y: 0 },
    srcSize: { w: 64, h: 64 },
    destPosition: { x: 0, y: 0 },
    destSize: { w: 64, h: 64 },
    isGalleryMode: false,
    isWallBool: false,
    isShadowMode: false,
    srcImageSize: null,

    SrcImageSize: { w: 30, h: 30 },
  };

  outputs = {
    painter: null,
    outputSize: null,
    outputSrcImageSize: { w: 20, h: 20 },
    callbackImageSize: { w: 0, h: 0 },
  } as Outputs;

  onInit() {
    this.maybeLoadImage();
    this.outputs.painter = this;

    this.outputs.outputSrcImageSize.h = this.image.width;
  }

  testfunction() {
    console.log("testfunction" + this.image.width);
    
  }

  onInputsUpdated() {
    this.maybeLoadImage();

    // 얘가 이미지 로드 되기 전에 실행 되서 주석처리함
    //this.paint(this.context(), this.getSize());
  }

  callbackImageSize(context2d: CanvasRenderingContext2D, size: Size): Size {
    if (!this.image || this.image.width == 0) {
      return null;
    }
    // const widthtest = this.image.width;
    // console.log(widthtest);
    this.outputs.outputSrcImageSize.w = this.image.width;
    this.outputs.outputSrcImageSize.h = this.image.height;
    return this.outputs.outputSrcImageSize;
  }
  outputSize() {
    return this.image.width;
  }

  returnSize() {
    this.outputs.outputSrcImageSize.w = this.image.width;
    this.outputs.outputSrcImageSize.h = this.image.height;
    return this.outputs.outputSrcImageSize;
  }

  paint(context2d?: CanvasRenderingContext2D, size?: Size): any {
    if (!this.image || this.image.width == 0) {
      return;
    }

    let planeWidth = 1000; // canvas 크기
    let planeHeight = 1000;
    let MaxplaneLength = 2000; // 최대 canvas 크기
   

    let imageWidth = this.image.width;
    let imageHeight = this.image.height;
    //let imageRatio = this.image.width / this.image.height;
    // console.log("this.image.width : " +this.image.width)
    // console.log("this.image.height : " + this.image.height);
    if(imageWidth <= imageHeight){// 너비 작을때 기준 
      imageHeight = (planeWidth / imageWidth) * imageHeight
      imageWidth = planeWidth;
      

    }else{// 높이가 더 클 때
      imageWidth = (planeHeight / imageHeight) * imageWidth;
      imageHeight = planeHeight;
    }

    if (imageWidth >= MaxplaneLength || imageHeight >= MaxplaneLength) {
      if (imageWidth >= imageHeight) { // 긴 너비를 기준으로 축소, 
        
        imageHeight = (imageWidth / MaxplaneLength )  *  imageHeight
        imageWidth = MaxplaneLength;
      } else {
        // 높이가 더 클 때
        imageWidth = (imageHeight / MaxplaneLength) * imageWidth;
        imageHeight = MaxplaneLength;
      }
    }


    //  console.log("imageWidth : "+imageWidth)
    //  console.log("imageheight : " + imageHeight);
    


    // if(this.inputs.isShadowMode&& this.inputs.srcSize!=null){
    //   imageRatio = this.imageSize.width / this.imageSize.height;
    //   //imageRatio = 2400/1600;
    // }
    // if(this.inputs.isShadowMode){
    //   imageRatio = 2400/1600;
    // }
    // const planeRatio = planeWidth / planeHeight;

    // let adjustWidth = planeWidth;
    // let adjustHeight = planeHeight;

    // if (imageRatio > planeRatio) {
    //   adjustHeight = planeWidth / imageRatio;
    // } else {
    //   adjustWidth = planeHeight * imageRatio;
    // }

    // if (this.image.width != 0) {
    //   this.outputs.outputSrcImageSize.w = this.image.width;
    //   this.outputs.outputSrcImageSize.h = this.image.height;

    //   // console.log(this.image.width);
    //   // console.log(this.image.height);
    //   // console.log(this.outputs.outputSrcImageSize);
    // }

    // if (this.inputs.srcImageSize != null) {
    //   console.log("this.inputs.srcImageSize : " + this.inputs.srcImageSize);
    // }
    // // let x = (planeWidth - adjustWidth) / 2; // 이게 아마 가운데 정렬 기준 시작 x 값
    // // let y = (planeHeight - adjustHeight) / 2;
    // //console.log(x);
    // //console.log(y);

    if (this.inputs.isWallBool) {
      // x = 0;
      // y = 0;
      imageWidth = 2000;
      imageHeight = 2000;
    }
    context2d.clearRect(0, 0, planeWidth, planeHeight);
    context2d.drawImage(this.image, 0, 0, imageWidth, imageHeight);

    //  context2d.clearRect(0, 0, this.inputs.srcSize.w, this.inputs.srcSize.h);
    //  if (this.image.width > 0) {
    //    context2d.drawImage(
    //      this.image,
    //      this.inputs.srcPosition.x,
    //      this.inputs.srcPosition.y,
    //      this.image.width,
    //      this.image.height,

    //    );
    //    console.log("this.inputs.srcPosition.x : " + this.inputs.srcPosition.x);
    //    console.log("this.inputs.srcPosition.x : " + this.inputs.srcPosition.y);
    //    console.log("this.inputs.srcSize.w *2 : " +this.inputs.srcSize.w * 2);
    //    console.log("this.inputs.srcSize.w *2 : " + this.inputs.srcSize.h * 2);
    //    console.log("image : "+ this.image.width);
    //    console.log("image : " + this.image.height);
    //  }
    this.outputs.outputSrcImageSize.w = this.image.width;
    this.outputs.outputSrcImageSize.h = this.image.height;
    
    return this.outputs.outputSrcImageSize;
  }

  private maybeLoadImage() {
    this.image = null;

    if (this.inputs.src !== null && this.inputs.src !== "") {
      const that = this;
      this.image = new Image(); // 사용할 이미지
      this.imageSize = new Image();
      // this.image.width = 800;
      // this.image.height = 450;
      this.image.crossOrigin = "anonymous";
      this.image.src = this.inputs.src;
      //console.log("this.image.height : " + this.image.height);
      if (this.inputs.isShadowMode) {
        this.imageSize.src = this.inputs.srcImageSize; // 크기 용 이미지,
      }
      if (this.image.onload) {
        this.outputs.outputSrcImageSize.w = this.image.width;
        console.log("onload : " + this.image.width);
      }
      //console.log("test1 : " + this.image.width);
      this.image.onload = function (event: Event) {
        that.notify("paint.ready");
      };
    }
    // this.notify("paint.ready"); // 주목
  }
}

export const canvasImageType = "mp.canvasImage";
export function makeCanvasImage() {
  return new CanvasImage();
}
