import { SceneComponent, ComponentOutput } from "@mp/common";
import { VideoTexture } from "three";
import Hls from "hls.js";

type Inputs = {
  src: string;
  enabled: boolean;
};

type Outputs = {
  video: HTMLVideoElement | null;
  aspect: number;
} & ComponentOutput;

class HlsLoader extends SceneComponent {
  private video: HTMLVideoElement | null = null;
  private texture: VideoTexture | null = null;
  private hls: Hls | null = null;

  private firstCreateVideo : boolean = true;
  inputs: Inputs = {
    src: "",
    enabled: false,
  };

  outputs = {
    video: null,
    aspect: 1,
  } as Outputs;

  onInit() {
    this.outputs.aspect = 100 / 100;
    if (this.inputs.enabled) {
      this.setupStream();
    }
  }

  onInputsUpdated() {
    this.setupStream();
    //console.log("this.inputs.enabled : " + this.inputs.enabled);
     if (!this.inputs.enabled) {
       //console.log("!this.inputs.enabled");
       // console.log("비디오 인풋 확인1");
       this.video.muted = false;
     } else {
       //console.log("!this.inputs.disbled");
       // console.log("비디오 인풋 확인2");
       this.video.muted = true;
     }
  }

  onDestroy() {
    this.releaseResources();
  }

  private createVideoElement() {
    const video = document.createElement("video");
    video.crossOrigin = "anonymous";
    video.setAttribute("height", "480");
    video.setAttribute("width", "720");
    
    video.volume = 0.1;

    return video;
  }

  private setupStream() {
    this.releaseResources();

    if (this.inputs.enabled && this.firstCreateVideo) {
      //console.log("비디오가 생성 되었습니다! ");
      this.video = this.createVideoElement();
      this.hls = new Hls();
      this.hls.loadSource(this.inputs.src);
      this.hls.attachMedia(this.video);
      this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
        this.outputs.video = this.video;
      });
      this.firstCreateVideo = false;
    }
  }

  private releaseResources() {
    
    if (this.hls) {

      //this.hls.destroy();
      //this.hls = null;
    }

    if (this.texture) {
       //얘때문에 없어지나? 
      this.texture.dispose();
      this.texture = null;
      this.outputs.video = null;
    }
  }
}

export const hlsLoaderType = "mp.hlsLoader";
export function makeHlsLoader() {
  return new HlsLoader();
}
