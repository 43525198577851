import { ISceneNode } from "@mp/common";
import axios from "axios";

export class SceneLoader {
  private nodes: ISceneNode[] = [];

  constructor(private sdk: any) {}

  /**
   * Load the scene for a given model.
   *
   * @param sid sid of the model, used to lookup the scene.
   * @param callback an optional callback which is called once for scene node created.
   */
  public async load(sid: string, callback: (node: ISceneNode) => void = null) {
    const nodesToStop = this.nodes.splice(0);
    for (const node of nodesToStop) {
      node.stop();
    }
    const userAgent = navigator.userAgent.toLowerCase(); // User Agent 문자열을 소문자로 변환하여 저장
    if (userAgent.indexOf("mobile") !== -1) {
      console.log("모바일 디바이스입니다.");
      if (
        userAgent.indexOf("iphone") !== -1 ||
        userAgent.indexOf("ipad") !== -1
      ) {
        console.log("iOS 디바이스입니다.");
        // iOS 디바이스 처리 로직 작성
      } else if (userAgent.indexOf("android") !== -1) {
        console.log("안드로이드 디바이스입니다.");
        // 안드로이드 디바이스 처리 로직 작성
      } else {
        console.log("모바일 디바이스이지만, iOS나 안드로이드가 아닙니다.");
        // 기타 모바일 디바이스 처리 로직 작성
      }
    } else if (userAgent.indexOf("tablet") !== -1) {
      console.log("태블릿 디바이스입니다.");
      // 태블릿 디바이스 처리 로직 작성
    } else if (
      userAgent.indexOf("macintosh") !== -1 ||
      userAgent.indexOf("mac os x") !== -1
    ) {
      console.log("맥북입니다."); // 맥북 디바이스 처리 로직 작성
    } else {
      console.log("데스크톱 디바이스입니다.");
      // 데스크톱 디바이스 처리 로직 작성
    }

    var url;

    const urltmp = window.location.href;

    url = new URL(urltmp);

    const urlParams = url.searchParams;

    urlParams.get("exhibitioncode");
    if (urlParams.get("m") == "PkotDZTXfZo") {
      const response = await axios.get(
        "https://api.dtype.360xcon.com/admin/exhibition/" +
          urlParams.get("exhibitioncode") +
          "/booths/all-informations"
      );

      const responseIntro = await axios.get(
        "https://api.dtype.360xcon.com/admin/exhibition/" +
          urlParams.get("exhibitioncode") +
          "/intro/"
      );
      //  console.log(responseMain);
      //  console.log(responseMain.data.thumbnailUrl);

      var img = new Image(); //이미지 객체 선언

      img.src = response.data.booths[0].assetUrl;

      const importJsonFile = require("../../vs-app/assets/" +
        urlParams.get("m") +
        ".json"); //전시 Json 파일 불러오기
      //console.log(response.data);
      //24
      var introInformationsString = responseIntro.data;
      // const IntroInfomation = {
      //   title: introInformationsString.title,
      //   thumbnailUrl: introInformationsString.thumbnailUrl,
      //   bgmUrl: introInformationsString.bgmUrl,
      //   description: introInformationsString.description,
      //   profileUrl: introInformationsString.profileUrl,
      //   exhibitionType: introInformationsString.exhibitionType,
      // };
      //      console.log(IntroInfomation);

      let introTextString =
        "\n" +
        introInformationsString.title +
        "\n\n" +
        introInformationsString.description +
        "\n";

      const introInputsCode = {
        opacity: 1,
        isGalleryMode: true,
        src: introInformationsString.thumbnailUrl,
        srcPosition: {
          x: 0,
          y: 0,
          z: 0,
        },
        srcSize: {
          w: 1000,
          h: 1000,
        },
        destPosition: {
          // 안씀
          x: 0,
          y: 0,
          z: 0,
        },
        destSize: {
          // 안씀
          w: 1000,
          h: 1000,
        },
      };

      //console.log(importJsonFile.payload.objects[23].name);
      if (importJsonFile.payload.objects[23] != null) {
        importJsonFile.payload.objects[23].components[0].inputs =
          introInputsCode;

        importJsonFile.payload.objects[23].components[2].inputs.informationsString =
          introTextString;

        importJsonFile.payload.objects[23].components[5].inputs.text =
          introTextString;

        console.log(importJsonFile.payload.objects[23]);
      }

      for (let i = 0; i < 20; i++) {
        // 20개 삽입
        var imageUrl = response.data.booths[i].assetUrl;
        if(i==0){
          console.log(response.data.booths[i].assetUrl);
        }
        var informationsString = response.data.booths[i].informations;

        const inputsCode = {
          opacity: 1,
          src: imageUrl,
          srcPosition: {
            x: 0,
            y: 0,
            z: 0,
          },
          srcSize: {
            w: 1000,
            h: 1000,
          },
          destPosition: {
            // 안씀
            x: 0,
            y: 0,
            z: 0,
          },
          destSize: {
            // 안씀
            w: 1000,
            h: 1000,
          },
        };
        let createdDate = response.data.booths[i].informations.createdDate;
        let year;
        
        if (createdDate) { year = createdDate.split("T")[0].split("-")[0];
       }
        else { year = '-' }
        // let year  = createdDate;   //.substring(0, 4);
        let TextString1 =
          "\n작가명 : " +
          response.data.booths[i].informations.artist_name +
          "\n재료 : " +
          response.data.booths[i].informations.material +
          "\n크기 : " +
          response.data.booths[i].informations.size +
          "\n년도 : " +
          year + "년" +
          "\n작품명 : " +
          response.data.booths[i].informations.piece_name +
          "\n소장처 : " +
          response.data.booths[i].informations.owner +
          "\n";

        if (importJsonFile.payload.objects[i] != null) {
          importJsonFile.payload.objects[i].components[0].inputs = inputsCode;

          importJsonFile.payload.objects[
            i
          ].components[2].inputs.informationsString = informationsString;

          if (importJsonFile.payload.objects[i].components[5] != null) {
            // textComponent가 있다면
            importJsonFile.payload.objects[i].components[5].inputs.text =
              TextString1;
          }

          importJsonFile.payload.objects[
            i
          ].components[2].inputs.DrawUrlInformationsString.drawUrl = imageUrl;
        }
      }
    }

    const sceneImport = await import(
      "../assets/" + urlParams.get("m") + ".json"
    );

    const sidToScene: Map<string, any> = new Map();

    sidToScene.set("AAWs9eZ9ip6", sceneImport);

    let scene = sidToScene.get(sid);

    if (!scene) {
      return;
    }

    const nodesToStart: ISceneNode[] = await this.sdk.Scene.deserialize(
      JSON.stringify(scene)
    );

    if (callback) {
      for (const node of nodesToStart) {
        callback(node);
      }
    }
    let imgTest2 = new Image();

    for (const node of nodesToStart) {
      let frameString = node.name.replace(/[0-9]/g, "");

      if (frameString == "image") {
        // image로 시작할경우 glb 역자 추가
        let frameNumber = parseInt(node.name.split("e")[1]);

        imgTest2.src = await sceneImport.payload.objects[frameNumber]
          .components[0].inputs.src;

        const imageLoadPromise = await new Promise<void>((resolve) => {
          imgTest2.onload = function () {
            resolve();
          };
        });
        await imgTest2.width;
        await imageLoadPromise;
        imgTest2.onload = function () {
          console.log("imgTest2.width : " + imgTest2.width);
          console.log("imgTest2.height : " + imgTest2.height);
        };


        let planeWidth = 1000;
        let planeHeight = 1000;
        let MaxplaneLength = 2000;

        let imageWidth = imgTest2.width;
        let imageHeight = imgTest2.height;

        


        let framePositionX = 0;
        let framePositionY = 0;
        //  console.log("imgTest2.width : " + imgTest2.width);
        //  console.log("imgTest2.height : " + imgTest2.height);
        //let imageRatio = this.image.width / this.image.height;
        
        
        if (imageWidth <= imageHeight) { // 이미지 크기 균일화 
          // 너비 작을때 기준
          imageHeight = (planeWidth / imageWidth) * imageHeight;
          imageWidth = planeWidth;
          

        } else {
          // 높이가 더 클 때
          imageWidth = (planeHeight / imageHeight) * imageWidth;
          imageHeight = planeHeight;
        }

        framePositionX = (MaxplaneLength - imageWidth) * 0.0001;
        framePositionY = (MaxplaneLength - imageHeight) * 0.0001;

        // console.log(imageWidth, imageHeight);

        if (imageWidth >= MaxplaneLength || imageHeight >= MaxplaneLength) {//최대치 초과시 가로 최대 너비로 축소함. 
          if (imageWidth >= imageHeight) {
            // 긴 너비를 기준으로 축소,

            imageHeight = (imageWidth / MaxplaneLength) * imageHeight;
            imageWidth = MaxplaneLength;
          } else {
            // 높이가 더 클 때
            imageWidth = (imageHeight / MaxplaneLength) * imageWidth;
            imageHeight = MaxplaneLength;
          }
          framePositionX = (MaxplaneLength - imageWidth) * 0.0001;
          framePositionY = (MaxplaneLength - imageHeight) * 0.0001;
        }
        // console.log(framePositionX, framePositionY);
        // let imageRatio2 = imgTest2.width / imgTest2.height;
        // let planeRatio2 = planeWidth2 / planeHeight2;
        // let adjustWidth2 = planeWidth2;
        // let adjustHeight2 = planeHeight2;

        // if (imageRatio2 > planeRatio2) {
        //   adjustHeight2 = planeWidth2 / imageRatio2;
        // } else {
        //   adjustWidth2 = planeHeight2 * imageRatio2;
        // }
        // console.log("width : "+ adjustWidth2)
        // console.log("height : " + adjustHeight2);
        // let positionHeight =0;
        // if((adjustWidth2 / adjustHeight2) >= 1.5){
        //   positionHeight = ((adjustWidth2) - adjustHeight2) * 0.00003;
        // }
        // console.log("imageHeight : " + imageHeight);
        // console.log("imageWidth : " + imageWidth);
        var initial = {
          url: "https://vrmuseumstorage.blob.core.windows.net/vrmuseumblob1/test/frame_with_shadow_2.glb",
          visible: true,
          localScale: {
            x: imageWidth * 0.0004,
            y: imageHeight * 0.0004,
            z: 2.5,
          },
          localPosition: {
            x: -framePositionX,
            y: framePositionY,
            z: -0.0125,
          },
          rotation: {
            x: 0,
            y: sceneImport.payload.objects[frameNumber].rotation.y,
            z: 0,
          },
        };

        node.addComponent("mp.gltfLoader", initial);
      }
      node.start();
      this.nodes.push(node);
    }
  }

  public *nodeIterator(): IterableIterator<ISceneNode> {
    for (const node of this.nodes) {
      yield node;
    }
  }
}
