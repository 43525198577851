import { SceneComponent, ComponentOutput } from "../SceneComponent";
import type { Texture } from "three";
import { Size } from "./PlaneRenderer";

export interface IPainter2d {
  paint(context2d?: CanvasRenderingContext2D, size?: Size): any;
  
}
export interface test1{
  testfunction() :any;
}

type Inputs = {
  painter: IPainter2d | null;
  painter2: test1 | null;
  textureRes: Size;
  srcSizeOutputs: { x: number; y: number; z: number };
  outputSize: Size;
  SrcImageSize : {w: number, h: number};
};

type Outputs = {
  texture: Texture | null;
} & ComponentOutput;

type Events = {
  repaint: boolean;
};

class CanvasRenderer extends SceneComponent {
  private canvas: HTMLCanvasElement;
  private renderContext2D: CanvasRenderingContext2D;

  inputs: Inputs = {
    painter: null,
    painter2: null,
    textureRes: { w: 256, h: 256 },
    srcSizeOutputs: { x: 1, y: 1, z: 1 },
    outputSize: null,
    SrcImageSize : {w:0, h:0},
  };

  outputs = {
    texture: null,
  } as Outputs;

  events = {
    repaint: true,
  } as Events;

  onInit() {
    const THREE = this.context.three;

    // set up canvas 2d context
    this.canvas = document.createElement("canvas");
    this.renderContext2D = this.canvas.getContext("2d");
    this.outputs.texture = new THREE.CanvasTexture(this.canvas); // 여기서 기본 캔버스 크기를 가져감

    //console.log(this.inputs.painter);
    // console.log("this.inputs.srcSizeOutputs: ");
    // console.log(this.inputs.srcSizeOutputs);
    //console.log(this.inputs.painter.size);
    this.resize(this.inputs.textureRes);

    this.repaint();
  }

  onInputsUpdated(oldInputs: Inputs) {
    if (
      oldInputs.textureRes.w !== this.inputs.textureRes.w ||
      oldInputs.textureRes.h !== this.inputs.textureRes.h
    ) {
      this.resize(this.inputs.textureRes);
    }

    if (oldInputs.painter !== this.inputs.painter) {
        // console.log("canvasRenderer 이미지 repaint 순서 확인");
      //this.repaint();
    }
  }

  onEvent(eventType: string, _eventData: unknown) {
    if (eventType === "repaint") {
     // console.log("이벤트 동작 확인");
      this.repaint();
    }
  }

  onDestroy() {
    this.outputs.texture.dispose();
    this.outputs.texture = null;
  }

  private resize(size: Size) {
    this.canvas.width = size.w;
    this.canvas.height = size.h;
  }
  
  private repaint() {
    //console.log(this.inputs.painter);
    if (this.inputs.painter) {
      this.inputs.painter.paint(this.renderContext2D, this.inputs.textureRes); // 여기서 다시 repainting 호출,  canvas에 크기 지정해서 다시 칠한다. 이건 그냥 칠하기네 
      // var test11:Size = this.inputs.painter.paint(
      //   this.renderContext2D,
      //   this.inputs.textureRes
      // );
      // console.log(test11);
      // this.inputs.SrcImageSize = test11;
      // //this.inputs.SrcImageSize.h = test11.h;

      // console.log(this.inputs.SrcImageSize);
      // console.log(test11.w);

      

      //console.log(this.inputs.painter2.testfunction());
      // console.log(this.inputs.painter.paint.name);
      // console.log(this.inputs.painter2.paint.name);

      // 여기서 image size를 함수로 불러와야 할듯,

      this.outputs.texture.needsUpdate = true;
    }
  }
}

export interface ICanvasRenderer extends SceneComponent {
  inputs: Inputs;
  outputs: Outputs;
}

export const canvasRendererType = "mp.canvasRenderer";
export function makeCanvasRenderer() {
  return new CanvasRenderer();
}
