import React, { Component } from 'react';

import {  DrawItemDesc } from "../types";
import Modal from "@material-ui/core/Modal";

interface State {
  open: boolean;
  selectedItem: DrawItemDesc | null;
}
import {IComponentEventSpy, IPlaneInteractionEvent} from "@mp/common"

interface Props {
  DrawItems: DrawItemDesc;
  onSelected?: (DrawItem: DrawItemDesc) => void;
}

export class DrawItemList
  extends Component<Props, State>
  implements IComponentEventSpy
{
  constructor(props: Props) {
    super(props);

    this.state = {
      open: false,
      selectedItem: null,
    };
    console.log("1111 확인");
    this.onClick = this.onClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  eventType: string;
  onEvent(eventData?: unknown): void {
   
    throw new Error('Method not implemented.');

  }

  componentDidMount() {}

  onClick(event: IPlaneInteractionEvent) {
   
    this.setState({ selectedItem: this.props.DrawItems, open: true });
  }

  // onClick(event: React.MouseEvent) {
  //   console.log("drawitem 클릭 확인");
  //   console.log(this.state.selectedItem);
  //   console.log(this.state.selectedItem.artist_name);
  //   this.setState({ selectedItem: this.props.DrawItems, open: true });
  // }
  // onEvent(eventType: string, eventData: unknown) {
  //   console.log("eventType:" + eventType);
  //   console.log("eventData:" + eventData);
  // }

  handleClose() {
    this.setState({ open: false });
  }

  render() {
   
    return (
      <div>
        <Modal
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div>
            <h2 id="simple-modal-title">
              {this.state.selectedItem?.artist_name}
              {111111111111111111111111111111111111111}
            </h2>
            <p id="simple-modal-description">
              {this.state.selectedItem?.artist_detail}
              {22222222222222222222222222222222222222}
            </p>
          </div>
        </Modal>
      </div>
    );
  }
}