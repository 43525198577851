import { SceneComponent, ComponentOutput, Size, IPainter2d } from "@mp/common";

type Inputs = {
  position: { x: number; y: number };
  size: Size;
  radius: number;
  text: string;
  font: string;
  letterLineSpacing: number;
  srcSizeInputs: { x: number; y: number; z: number };
  SrcImageSize: { w: number; h: number } | null;
  painter: IPainter2d | null;
  textWidth: number;
  isIntro: boolean;
};

type Outputs = {
  painter: IPainter2d | null;
} & ComponentOutput;

type Events = {
  repaint: boolean;
};
class CanvasText extends SceneComponent implements IPainter2d {
  inputs: Inputs = {
    srcSizeInputs: { x: 1, y: 1, z: 1 },
    position: { x: 0, y: 0 },
    size: { h: 50, w: 25 },
    radius: 1,
    text: "Placeholder",
    font: "normal bold 10px sans-serif",
    letterLineSpacing: 22,
    SrcImageSize: { w: 10, h: 10 },
    painter: null,
    textWidth: 0,
    isIntro : false,
  };

  outputs = {
    painter: null,
  } as Outputs;

  events = {
    repaint: true,
  } as Events;

  onInit() {
    //console.log(this.inputs.srcSizeInputs);
    this.outputs.painter = this;

    this.repaint();
  }

  onInputsUpdated(oldinputs: Inputs) {
    if (oldinputs.painter !== this.inputs.painter) {
      console.log("업데이트 확인");

      // this.resize(this.inputs.SrcImageSize)
    }
    if (oldinputs.position !== this.inputs.position) {
      console.log("position 업데이트 확인");
      //  console.log("리페인트 : " + this.inputs.SrcImageSize.w);
      //  console.log("리페인트 : " + this.inputs.SrcImageSize.h);
      //  console.log(this.inputs.position.x);
      //  console.log(this.inputs.position.y);
    }
    if (oldinputs.SrcImageSize !== this.inputs.SrcImageSize) {
      console.log("srcImage 업데이트 확인");
      this.notify("paint.ready");
    }
  }

  onEvent(eventType: string, eventData: unknown) {
    if (eventType === "repaint") {
      //console.log("canvasText 이벤트 동작 확인");
      this.repaint();
    }
  }
  resize(sizes: any) {
    this.inputs.position.x = sizes.w;
    this.inputs.position.y = sizes.h;
    // console.log("this.inputs.position");
    // console.log(this.inputs.position);
  }
  repaint() {
    //console.log("input "+this.inputs.SrcImageSize.)
    // console.log("리페인트 : " + this.inputs.SrcImageSize.w);
    // console.log("리페인트 : " + this.inputs.SrcImageSize.h);
    // console.log(this.inputs.position.x);
    // console.log(this.inputs.position.y);
    this.inputs.position.x = this.inputs.SrcImageSize.w;
    this.inputs.position.y = this.inputs.SrcImageSize.h;

    // console.log(this.inputs.position.x);
    // console.log(this.inputs.position.y);
    // if (this.inputs.SrcImageSize.w != 0 || this.inputs.SrcImageSize.h != 0) {
    //   this.inputs.position.x = this.inputs.SrcImageSize.w ;
    //   this.inputs.position.y = this.inputs.SrcImageSize.h ;
    // }
  }

  paint(context2d: CanvasRenderingContext2D, size: Size): void {
    context2d.strokeStyle = "rgb(0, 0, 0)"; //"rgb(27, 27, 28)";
    context2d.fillStyle = "rgb(0, 0, 0)"; //"rgb(27, 27, 28)";


    context2d.font = this.inputs.font;
    context2d.textAlign = "left";
    context2d.textBaseline = "top";

    let planeWidth = 1000; // canvas 크기
    let planeHeight = 1000;
    let MaxplaneLength = 2000; // 최대 canvas 크기

    let imageWidth = this.inputs.SrcImageSize.w;
    let imageHeight = this.inputs.SrcImageSize.h;
    if (imageWidth <= imageHeight) {
      // 너비 작을때 기준
      imageHeight = (planeWidth / imageWidth) * imageHeight;
      imageWidth = planeWidth;
    } else {
      // 높이가 더 클 때
      imageWidth = (planeHeight / imageHeight) * imageWidth;
      imageHeight = planeHeight;
    }

    if (imageWidth >= MaxplaneLength || imageHeight >= MaxplaneLength) {
      if (imageWidth >= imageHeight) {
        // 긴 너비를 기준으로 축소,

        imageHeight = (imageWidth / MaxplaneLength) * imageHeight;
        imageWidth = MaxplaneLength;
      } else {
        // 높이가 더 클 때
        imageWidth = (imageHeight / MaxplaneLength) * imageWidth;
        imageHeight = MaxplaneLength;
      }
    }

    this.inputs.position.x = imageWidth * 0.26 + 280;
    this.inputs.position.y = imageHeight * 0.285 + 80;
    if(this.inputs.isIntro){

      //전시명일때만 폰트 크기와 줄간격을 크게 조정 

      

      size.w = this.inputs.textWidth
    }
    wrapText(
      context2d,
      this.inputs.text,
      this.inputs.position.x,
      this.inputs.position.y,
      size.w,
      this.inputs.letterLineSpacing,
      this.inputs.isIntro
    );
  }
}

function wrapText(
  context: CanvasRenderingContext2D,
  text: string,
  x: number,
  y: number,
  maxWidth: number,
  lineHeight: number,
  isIntro?: boolean
) {
  //console.log(this.context.font)
  // 폰트 사이즈를 수정할수 있는지 테스트 해보고, 되면, 첫 \n \n 사이에 있는 글자는 크기와 줄간격을 크게,
  // 두 
  let srcFont = context.font;
 


  var lines = text.split("\n"); // 여기서 한글자씩 적음,
  for (var i = 0; i < lines.length; i++) {
    var words = lines[i].split(" ");
    var line = "";
    for (var n = 0; n < words.length; n++) {
      var testLine = line + words[n] + " ";
      var metrics = context.measureText(testLine);
      var testWidth = metrics.width;
      if (testWidth > maxWidth && n > 0) {
        context.fillText(line, x, y);
        line = words[n] + " ";
        y += lineHeight;
      } else {
        line = testLine;
      }
    }
    if (isIntro && i == 1) {
      console.log(line, x, y);
      console.log(context.font);
      context.font = "bold 20px sans-serif";
      y += 75;

    }
    
    context.fillText(line, x, y); // 이게 한줄인듯? 
    context.font = srcFont;
    y += lineHeight;
  }
}

export const canvasTextType = "mp.canvasText";
export function makeCanvasText() {
  return new CanvasText();
}
